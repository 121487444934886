export const update20250225 = {
    title: `Box of Trouble Update`,
    content:  `Crossed the 115K word mark on the sequel, and pushed to git repo. Just in time... about to leave for the @valaratomics
 launch party.
<br/><br/>
Excited. 
<br/><br/>
May also have special surprise report on other cool thing, but can't talk about it until NDA is done, and I'm clear on what is and isn't public information. 
<br/><br/>
I hate flying, but this trip will be worth it. 
`,
    link: 'https://x.com/Devon_Eriksen_/status/1894439323652571312',
    date: '2025-02-25',
    type: 'twitter',
    tag: 'updates',
    };